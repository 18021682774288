(function () {
  // add active-video class to wordpress video block to hide play icon when video is playing
  const embedVideo = document.querySelector('.wp-block-video video');
  if (embedVideo) {
    embedVideo.addEventListener('click', () => {
      document.querySelector('.wp-block-video').classList.add('active-video');
    });
  }

  const embedVideoListing = document.querySelectorAll(
    '.video-capsule-item__video'
  );
  if (embedVideoListing) {
    embedVideoListing.forEach((video) => {
      video.addEventListener('click', () => {
        video.classList.add('active-video');

        const iframe = video.querySelector('iframe');
        const videoPlayer = video.querySelector('video');

        if (iframe) {
          iframe.src += '&autoplay=1&autopause=0';
        } else if (videoPlayer) {
          videoPlayer.play();
        }
      });
    });
  }
})(jQuery);
