window.addEventListener('load', function () {
  function primaryButton() {
    $(
      '.wp-block-button:not(.is-style-underline) .wp-block-button__link'
    ).addClass('btn');

    $('.facetwp-load-more').addClass('btn');

    $('.facetwp-facet-team_filters .facetwp-radio:first-child').addClass('btn');

    const htmlBtns = $('.btn');

    if (!htmlBtns) {
      return;
    }

    htmlBtns.each(function (index, element) {
      const btnTxtAttribute = element.innerHTML;
      const btnSvg =
        '<svg class="outline" >' +
        '<defs><linearGradient id="gradient" x1="0" x2="0" y1="80%" y2="0" gradientTransform="rotate(270)"><stop offset="0%" stop-color="#0C95D3" stop-opacity="0.8" /><stop offset="100%" stop-color="#013A81" stop-opacity="0.9"/></linearGradient></defs>' +
        '<rect x="1" y="1" rt="5" stroke="url(#gradient)" class="outline__line" style="stroke-dasharray: 40%, 100%; stroke-dashoffset: 60%;"></rect>' +
        '<rect x="1" y="1" rt="5" stroke="url(#gradient)" class="outline__line" style="stroke-dasharray: 40%, 100%; stroke-dashoffset: 60%;"></rect>' +
        '<rect x="1" y="1" rt="5" class="outline__line" style="stroke-dasharray: 0, 30%;"></rect>' +
        '<rect x="1" y="1" rt="5" class="outline__line" style="stroke-dasharray: 0, 30%;"></rect></svg>' +
        btnTxtAttribute;
      element.innerHTML = btnSvg;
    });
  }

  primaryButton();

  document.addEventListener('facetwp-loaded', function () {
    primaryButton();

    const btns = document.querySelectorAll('.facetwp-radio');

    btns.forEach((el) => {
      el.addEventListener('click', (e) => {
        window.FWP.auto_refresh = true;
        const btnElmt = e.target.closest('.checked');

        if (btnElmt) {
          btnElmt.classList.add('is-active');
          window.FWP.auto_refresh = false;
        }
      });
    });
  });

  const filtersButton = document.querySelectorAll('.btn__search-filter');

  if (0 < filtersButton.length) {
    filtersButton.forEach((btn) => {
      btn.addEventListener('click', () => {
        filtersButton.forEach((el) => {
          el.classList.remove('btn__search-filter--active');
        });
        btn.classList.add('btn__search-filter--active');
      });
    });
  }
});
