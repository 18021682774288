import TomSelect from 'tom-select/dist/js/tom-select.complete.min.js';
let fieldsAreInitialized = false;

function changeFileUpload() {
  const multiFileUploads = document.querySelectorAll(
    '.gform_fileupload_multifile'
  );
  if (0 < multiFileUploads.length) {
    multiFileUploads.forEach(function (fileUpload) {
      fileUpload.querySelector('.gform_drop_instructions').innerHTML =
        wp.i18n._x(
          'Drop files here or <span>Select files</span>',
          'drag and drop instruction',
          'vtx'
        );
    });
  }
}

document.addEventListener('gform_post_render', function () {
  fieldsAreInitialized = true;
  changeFileUpload();
});

function customDropdownSelect() {
  const formDropdownFields = document.querySelectorAll('.gfield_select');

  if (0 < formDropdownFields.length) {
    formDropdownFields.forEach(function (dropdownField) {
      new TomSelect(dropdownField);
    });
  }
}

// ON READY
window.addEventListener('load', function () {
  if (!fieldsAreInitialized) {
    changeFileUpload();
    customDropdownSelect();
  }
});
