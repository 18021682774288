import Swiper from 'swiper/bundle';
window.addEventListener('load', function () {
  const popularSearches = document.querySelectorAll('.popular-keywords__list');

  if (!popularSearches) {
    return;
  }

  new Swiper('.popular-keywords__list', {
    direction: 'horizontal',
    watchOverflow: true,
    slidesPerView: 'auto',
    spaceBetween: 10,
    navigation: {
      nextEl: '.popular-keywords__nav-next',
      prevEl: '.popular-keywords__nav-prev',
    },
  });
});
