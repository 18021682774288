document.addEventListener('facetwp-loaded', function () {
  const administrationFilter = document.querySelector(
    '[data-value="conseil-dadministration"]'
  );
  if (!administrationFilter) {
    return;
  }

  if (!administrationFilter.classList.contains('checked')) {
    const memberItemList = document.querySelectorAll('.member-item');
    if (0 < memberItemList.length) {
      const loadMoreButton = document.querySelector('.facetwp-load-more');

      if (loadMoreButton) {
        memberItemList.forEach((member) => {
          const isAdministration = member.dataset.isAdministration;

          if ('true' === isAdministration) {
            member.style.display = 'none';
          }
        });
      }
    }
  }
});
